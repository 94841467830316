









import { Component, Vue, Prop } from 'vue-property-decorator'

import BaseIcon from '@/components/base/BaseIcon.vue'

@Component({
  name: 'PremiumLabel',
  components: {
    BaseIcon,
  },
})
export default class PremiumLabel extends Vue {
  @Prop()
  icon!: string

  baseUrl = process.env.VUE_APP_URL
}
