













































import { Nullable } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { toLocalCurrency } from '@/utils/currencyFormatter'
import { namespace } from 'vuex-class'
const PlansStore = namespace('PlansStore')
const PlaceTypesStore = namespace('PlaceTypesStore')
import { PlaceTypesModel } from '@/core/models/PlaceTypesModel'
import { PlanModel, OfferModel } from '@/core/models/PlanModel'

import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseSelectNew from '@/components/forms/BaseSelectNew/BaseSelect.vue'
import PremiumLabel from './PremiumLabel.vue'

export interface OfferInterface {
  planId: number
  placeTypeId: number
  offerId: number
}

interface ItemInterface {
  id: number
  name: string
}

@Component({
  name: 'SelectOffer',
  components: {
    BaseIcon,
    BaseButton,
    BaseSelectNew,
    PremiumLabel,
  },
})
export default class SelectOffer extends Vue {
  @Prop({ required: true })
  value!: OfferInterface

  @PlansStore.Getter
  public planList!: PlanModel[]

  @PlaceTypesStore.Getter
  public placeTypesList!: PlaceTypesModel[]

  get currentPlaceType(): Nullable<PlaceTypesModel> {
    return (
      this.placeTypesList.find((item) => item.id === this.value.placeTypeId) ||
      null
    )
  }

  get plan(): Nullable<PlanModel> {
    return this.planList.find((plan) => plan.id === this.value.planId) || null
  }

  get planOffers(): OfferModel[] {
    return this.plan?.offers || []
  }

  get offers(): OfferModel[] {
    return (
      this.planOffers.filter(
        (offer) => offer.place_type === this.value.placeTypeId && offer.price
      ) || []
    )
  }

  get currentOffer(): Nullable<OfferModel> {
    return this.offers.find((offer) => offer.id === this.value.offerId) || null
  }

  get periodList(): ItemInterface[] {
    return [...new Set(this.offers.map((offer) => offer.period))].map(
      (period) => ({
        id: period,
        name: `${period} месяц`,
      })
    )
  }

  get sortList(): ItemInterface[] {
    const sorts = this.offers
      .map((offer) => offer.sort)
      .filter((sort) => sort) as number[]
    return [...new Set(sorts)].map((sort) => ({
      id: sort,
      name: `${sort} место`,
    }))
  }

  toLocalCurrency = toLocalCurrency

  updateSort(sort: number): void {
    const filtredBySort = this.offers.filter((offer) => offer.sort === sort)
    const exactOffer = filtredBySort.find(
      (offer) => offer.period === this.currentOffer?.period
    )
    if (exactOffer) {
      this.updateOffer(exactOffer.id)
      return
    }
    const differentOffer = filtredBySort.sort((a, b) => b.period - a.period)[0]
    if (differentOffer) {
      this.updateOffer(differentOffer.id)
    }
  }

  updatePeriod(period: number): void {
    const filtredByPeriod = this.offers.filter(
      (offer) => offer.period === period
    )
    const exactOffer = filtredByPeriod.find(
      (offer) => offer.sort === this.currentOffer?.sort
    )
    if (exactOffer) {
      this.updateOffer(exactOffer.id)
      return
    }
    const differentOffer = filtredByPeriod.sort(
      (a, b) => (b.sort || 0) - (a.sort || 0)
    )[0]
    if (differentOffer) {
      this.updateOffer(differentOffer.id)
    }
  }

  updateOffer(id: number): void {
    const newValue: OfferInterface = {
      planId: this.value.planId,
      placeTypeId: this.value.placeTypeId,
      offerId: id,
    }
    this.$emit('update', newValue)
  }
}
